import React       from "react";
import Layout      from "../components/Layout/Layout"
import { graphql } from 'gatsby';

const NotFoundPage = ({data}) => (
    <Layout categories={data.prismic.allMacrocategorys.edges.map(el => el.node)}
            documents={data.prismic.allDocumentss.edges[0].node}>
        <h1>
            NOT FOUND
        </h1>
        <p>
            You just hit a route that doesn&#39;t exist... the sadness.
        </p>
    </Layout>
)

export default NotFoundPage;
export const query = graphql`
    {
        prismic {
            allMacrocategorys(sortBy: position_ASC) {
                edges {
                    node {
                        name
                        icon
                        _meta {
                            uid
                            id
                        }
                    }
                }
            }
            allDocumentss {
                edges {
                    node {
                        attachment_3_4 {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        general_conditions {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        privacy_policy {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        electronic_signature {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
